import { Header } from './Header';
import Services from './Services';
import Contact from './Contact';
import HeroBackground from './HeroBackground';
import OurTeam from './OurTeam';
import Aboutus from './Aboutus';


function App() {
  return (
    <div className='bg-black text-white'>
      <Header/>
      <HeroBackground/>
      <Aboutus/>
      <Services/>
      <OurTeam/>
      <Contact/>
    </div>
    //className='lg:mx-48 pt-24'
  );
}

export default App;
