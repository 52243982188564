import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function HeroBackground() {
  return (
    <div>
      <div className="relative isolate overflow-hidden pt-14">
        <img
          src="./first.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div className="mx-auto max-w-2xl py-96 sm:py-56 md:py-64 lg:py-72 px-5">
          <div className="text-center">
            <h1 className="text-3xl font-medium tracking-tight sm:text-5xl">
              "Where architectural brilliance aligns with eco-responsibility"
            </h1>
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

            <p className="mt-2 sm:mt-6 text-xl sm:text-3xl font-medium leading-8 text-gray-300 ">
            Welcome to GBDC
            </p>
            </AnimationOnScroll>

          </div>
        </div>
      </div>

    </div>
  )
}
