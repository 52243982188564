import { AnimationOnScroll } from 'react-animation-on-scroll';

const people = [
    {
      name: 'Johannes Schäfer',
      role: 'DGNB & BNB Auditor, ESG Manager',
      imageUrl:
        './johannes.png',
      twitterUrl: '#',
      linkedinUrl: '#',
    },
    {
      name: 'Youssef Marzouk',
      role: 'LEED Associate, Design Consultant',
      imageUrl:
        './youssef.png',
      twitterUrl: '#',
      linkedinUrl: '#',
    },
    {
      name: 'Sherif Ghorab',
      role: 'Architect Specializing in Green Buildings & Energy Consulting',
      imageUrl:'sherif.png',
      twitterUrl: '#',
      linkedinUrl: '#',
    },
    // More people...
  ]
  
  export default function OurTeam() {
    return (
      <div id='team' className="lg:py-40 py-24 scroll-mt-12">
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl ">
            <h2 className="sm:text-5xl text-4xl font-medium mb-6">Meet Our Team</h2>
          </div>

          <ul
            role="list"
            className="mx-auto mt-14 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          >
            {people.map((person) => (
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

              <li key={person.name}>
                <img className="mx-auto h-64 w-60 rounded-full" src={person.imageUrl} alt="" />
                <h3 className="text-base font-medium leading-7 tracking-tight">{person.name}</h3>
                <p className="text-sm leading-6">{person.role}</p>
              </li>
              </AnimationOnScroll>

            ))}
          </ul>
        </div>
      </div>
    )
  }
  