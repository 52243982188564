import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Contact = () => {
  return (
<section>
  <div class="px-5 pt-12 sm:pb-10 pb-8 mx-auto border-t text-left">
    <div class="flex flex-col w-full">

      <h1 class="sm:text-4xl text-center text-2xl font-medium title-font mb-8 sm:mb-12">Here to help you meet your sustainability goals</h1>

      <div class="flex text-center">
      <div class="xl:w-1/2 lg:w-1/2 md:w-full px-8 py-6 flex justify-center items-center">
      <img className='lg:w-[30%] w-[80%]' src='./logo.jpeg' alt=''/>
      </div>

      <div class="flex flex-col justify-center items-center xl:w-1/2 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
        <div class="flex flex-col justify-center items-center mb-8">
        <h2 class="text-xl sm:text-2xl font-medium pb-1">Contact</h2>
        <a className='hover:text-gray-300 text-xs sm:text-lg' href="tel:+491635488352">+491635488352</a>
        <a className='hover:text-gray-300 text-xs sm:text-lg'  href='mailto:Contact@gb-dc.com'>Contact@gb-dc.com</a>       

        </div>
        <div class="flex flex-col justify-center items-center">
        <h2 class="text-xl sm:text-2xl font-medium pb-1">Address</h2>
        <p id="contact" class="leading-normal text-xs sm:text-lg ">Alfred-Herrhausen-Alle 3-5, 65760
            <br/> Eschborn, Germany
          </p>   
          </div>
      </div>
    </div>
    
    </div>
  </div>
</section>
  )
}

export default Contact