import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Aboutus = () => {
  return (
<section id="aboutus" className='scroll-mt-20' >
  <div class="lg:px-48 px-3 md:px-14 lg:py-36 py-20 relative isolate overflow-hidden">
  <img
          src="./second.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
    <div class="lg:px-48 px-8 md:px-14 flex flex-col text-center w-full mb-10">
      <h1 class="sm:text-5xl text-4xl font-medium mb-6">About Us</h1>
      <p class="mx-auto leading-relaxed sm:text-base text-sm">We are a dynamic team of architects and engineers specializing in green and sustainable building practices. With in-depth expertise in certifications like LEED, DGNB, and BNB, we offer tailored solutions for both new and existing buildings.</p>
    </div>
    <div class="flex flex-wrap text-left">
      <div class="xl:w-1/2 lg:w-1/2 md:w-full px-8 py-6 lg:border-l-0 border-l-2  ">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

        <h2 class="text-xl sm:text-2xl font-medium  pb-1  sm:mb-5 mb-3">Our Vision</h2>
          <ul class="leading-relaxed sm:text-base text-sm list-disc">
            <li>Supporting clients from initial concepts to final execution. </li>
            <li>Optimizing both new builds and existing structures. </li>
            <li>Ensuring the perfect blend of innovative design, functionality, and sustainability for various building types like residential and office spaces. </li>
          </ul>
        </AnimationOnScroll>

      </div>
      <div class="xl:w-1/2 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
        <h2 class="text-xl sm:text-2xl font-medium pb-1 sm:mb-5 mb-3">Our Mission</h2>       
          <p class="leading-relaxed sm:text-base text-sm">
          To shape a sustainable future by offering tailored architectural solutions that harmonize innovation with eco-conscious principles. Every structure we consult on embodies our commitment to reducing environmental impact, elevating design standards, and setting a benchmark in green building practices worldwide.
          </p>
        </AnimationOnScroll>

      </div>
    </div>
  </div>
</section>
  )
}

export default Aboutus