export const Header = () => {
    return (
    <header class="fixed top-0 w-full border-b-2 border-white bg-black z-50 sm:font-medium font-normal">
  <div class="container mx-auto flex flex-wrap p-2 md:pb-0 pb-2  flex-col md:flex-row items-center">
    <a href="#" class="cursor-pointer flex font-medium items-center mb-2 md:mb-0">
      <img className="sm:w-44 sm:h-26 w-24 " src='./logo.jpeg' alt=''/>
    </a>
    <nav class="md:ml-auto sm:text-lg text-sm flex flex-wrap items-center justify-center text-center">
      <a href="#aboutus" class="hover:text-gray-300 mx-5 cursor-pointer">About us</a>
      <a href="#services" class="hover:text-gray-300 mx-5 cursor-pointer">Services</a>
    <a href="#team"  class="hover:text-gray-300 mx-5 cursor-pointer">Team</a>
      <a href="#contact" class="hover:text-gray-300 mx-5 cursor-pointer">Contact</a>
    </nav>
  </div>
</header>
    )
}