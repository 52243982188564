import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Services = () => {
  return (
<section id="services" className='scroll-mt-14'>
  <div class="lg:py-36 py-20 relative isolate overflow-hidden">
  <img
          src="./third.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
    <div class="lg:px-48 px-8 md:px-14 flex flex-col text-center w-full mb-10">
      <h1 class="sm:text-5xl text-4xl font-medium mb-6">What We Do?</h1>
      <p class="mx-auto leading-relaxed sm:text-base text-sm">We specialize in providing a comprehensive suite of sustainable architectural services. Our offerings encompass advanced energy and design consulting, leveraging simulations for optimal efficiency.
      Drawing on extensive knowledge, we advise on the best global building certifications, offering custom solutions tailored to each project. Furthermore, our life cycle assessments ensure a holistic approach, prioritizing environmental sustainability and superior indoor air quality in every structure we engage with.</p>
    </div>
    <div class="flex flex-wrap text-center lg:px-32 px-3 md:px-14">
      <div class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 lg:border-l-0 border-l-2 ">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

      <h2 class="text-xl sm:text-2xl font-medium  pb-1 sm:mb-5 mb-3">Building Certification</h2>

        <p class="leading-relaxed sm:text-base text-sm mb-4">With expertise in various global certifications, we discern the optimal system tailored to each building. Our approach provides bespoke solutions, ensuring pinnacle standards in sustainable architecture.</p>
            </AnimationOnScroll>
  
      </div>
      <div class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

        <h2 class="text-xl sm:text-2xl font-medium  pb-1  sm:mb-5 mb-3">Energy & Design Consulting</h2>

        <p class="leading-relaxed sm:text-base text-sm mb-4">Utilizing advanced simulations, we integrate architectural design with energy efficiency. Our methodology focuses on optimizing energy consumption from the initial design phase.</p>
        </AnimationOnScroll>

      </div>
      <div class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">

        <h2 class="text-xl sm:text-2xl font-medium  pb-1  sm:mb-5 mb-3">Life Cycle Assessment</h2>

        <p class="leading-relaxed sm:text-base text-sm mb-4">Our evaluations prioritize both environmental impact and indoor air quality. By making strategic material and construction choices, we ensure structures promote health, sustainability, and inhabitant well-being.</p>
        </AnimationOnScroll>

      </div>
    </div>
    {/* <button class="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button> */}
  </div>
</section>
  )
}

export default Services